<template>
  <div class="sidebar">
    <div class="sidebar-backdrop z-60" @click="close()" v-if="isNavOpen"></div>
    <transition name="slide">
      <div v-if="isNavOpen" class="sidebar-panel bg-gray-500 pb-12">
        <div class="flex items-center justify-between px-4 py-3 mb-9">
          <router-link to="/">
            <g-2-academy color="white" width="128" />
          </router-link>
          <div @click="close()"><close color="white" /></div>
        </div>
        <div class="text-center mb-7" v-if="isUserEventLoggedIn">
          <div class="inline-flex items-center justify-center border border-white rounded-md py-1 px-2">
            <User color="white" /><span class="inline-block ml-2 text-white font-semibold text-sm">Hi, {{ userEvent.name }}</span>
          </div>
        </div>
        <div class="flex flex-col px-4">
          <slot name="menu"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    G2Academy: () => import(/* webpackChunkName: "icon" */ '@/components/icons/G2Academy'),
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Close'),
    User: () => import(/* webpackChunkName: "icon" */ '@/components/icons/User')
  },
  props: {
    isNavOpen: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('auth', ['userEvent', 'isUserEventLoggedIn'])
  },
  methods: {
    ...mapActions('auth', ['removeUserEvent']),
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 200ms ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  width: 300px;
}
</style>
